
@import '~bootstrap/scss/bootstrap';
@import '~material-design-icons/iconfont/material-icons.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";



@import './assets/forms.scss';
@import './assets/bs-datepicker.css';

.paginator250px{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: baseline;
  width: 250px;
  background-color: transparent;
  color: $primary;
  margin: 2px;
  .arrowxxl{
  font-size:2rem;
  cursor: pointer;
  border: 0px;
  background-color: transparent;
  color: $primary;
  }
  .pgnselect{
    color:$primary;
    border: 1px $primary;
  }
}

.contenthead{
  display: flex;
  flex-direction: row;
  justify-items: auto;
  justify-content: space-between;
  align-items: baseline;
  max-height: 25px;
  .w10{  max-width: 10%;  }
  .w20{  max-width: 20%;  }
  .w30{  max-width: 30%;  }
  .w40{  max-width: 40%;  }
  .w50{  max-width: 50%;  }
  .w60{  max-width: 60%;  }
  .w70{  max-width: 70%;  }
  .w80{  max-width: 80%;  }
  .w90{  max-width: 90%;  }
  .w100{  max-width: 100%;  }
}
.loading-indicator {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.ptr:hover{
  cursor: pointer;
}
.buttonrow{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}
.inputbox{
  border: 1px solid #c6d4f8;
  max-width: 80px;
  max-height: 40px;
  padding: 5px;
  margin: 5px;
  text-align: right;
 }

 .bg-white {
  background-color: rgb(255, 255, 255) !important;
  }
.bg-yelow {
background-color: rgb(245, 245, 151)!important;
}
.bg-green {
  background-color: rgb(180, 248, 180) !important;
  }
  .maxh100{
    max-height: 100%;
    position: relative;
  }
  .h50px{
    max-height: 10%;
  }

  .h10p{
    max-height: 10%;
  }
  .h70p{
    max-height: 70%;
  }
  .ofauto{
    overflow-y: auto;
    }

    .table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(55, 112, 242, 0.08);
    }

  // remove arrow from number input input field  ------------------------------------------------------------
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.inputqty{
  border: 1px solid #c6d4f8;
  margin: 2px;
  padding: 2px;
  max-width: 80px;
  max-height: 30px;
}
.txtRight{
  text-align: right !important;
  vertical-align: right !important;
}
.txtCenter{
  text-align: center !important;
  justify-content: center !important;
}
span.tab100{
  padding: 0 50px; /* 100px*/
}
.alignRight{
  text-align: right !important;
  justify-content: right !important;
}
.alignLeft{
  text-align: left !important;
  justify-content: left !important;
}
.alignCenter{
  text-align: center !important;
  justify-content: center !important;
}
.qtyspan{
  cursor: pointer;
}


// #############---------reduce mat control height and border color
::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #3f51b5; width: 100%; }
::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #007bff; width: 100%;}
::ng-deep .mat-form-field-wrapper {
  padding-bottom: 0em; width: 100%; height: 30px; margin: 25px 0 25px 0;}
// // reduce the hight of Material input field-----------------------------------------------------------------------------
::ng-deep mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix  { padding: 0.4em 2px ;}
::ng-deep  mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper { top: -1.5em; }

::ng-deep  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {   transform: translateY(-1.1em) scale(.75);  width: 133.33333%;}

// //reduce the height of material datepicker-----------------------------------------------------------------------------
::ng-deep .mat-datepicker-content .mat-calendar {  width: 229px!important;  height: 300px!important;}

// mobile ####################################
// @media all and (max-width:420px)
// {
// .buttonrow{
//   justify-content: space-evenly;
// }

// }

// html, body { height: 100%; overflow: hidden;}
// body { margin: 0;padding: 0px; font-family: Roboto, "Helvetica Neue", sans-serif; overflow: hidden;}

@media print { html, body { height: auto; overflow-y: visible !important; } }
@media screen {
  div.divPrintFooter {
    display: none;
  }
}
@media print {
  div.divPrintFooter {
    position: fixed;
    bottom: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    margin-top: 5px;
    padding-top: 5px;
  }
}
.divPrintFooter{
  text-align: center;
}
/* to avoide an empty print page ---------------------------------- */


// #printPage:last-child {
//   page-break-after: auto;
// }

// .print:last-child{
//   page-break-after: avoid;
//   page-break-inside: avoid;
//   margin-bottom: 0px;
// }
