/* You can add global styles to this file, and also import other style files */
.ng-invalid.ng-touched{
  border-color: red;
}
.error-block{
  color: brown;

}

.btn-group > label[ng-reflect-btn-radio]{
  border-radius: 44px;
  padding: 0 15px;
  line-height: 30px;
}


